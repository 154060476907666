<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Universidad Unión Bolivariana.</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">Desarrollado por</span>
      <a href="https://guidocutipa.blog.bo" target="_blank">Guido Cutipa Yujra</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
