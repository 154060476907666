<template>
  <CHeader fixed light with-subheader>
    <CToggler
        class="ml-3 d-lg-none"
        in-header
        @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
        class="ml-3 d-md-down-none"
        in-header
        @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img class="img-responsive" height="25px" :src="logoSistema"/>
    </CHeaderBrand>

    <CMenu/>

    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
      <form class="form-inline">
        <strong style="font-size: 24px;">Sede: </strong>
        <select v-model="sedeSeleccionada" class="form-control  form-control-lg bg-success text-white ml-3"
                @change="seleccionarSede()" id="sedeSeleccionada">
          <option v-for="sede in sedes" :key="sede.value" :value=sede.value
                  v-bind:selected="sedeSeleccionada==sede.value ? true : false">
            {{ sede.label }}
          </option>
        </select>
      </form>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">

        Usuario: &nbsp;<span class="text-info pr-2">{{ username }}</span>

      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">

        <CButton @click="logout()" class="btn btn-link border-left">
          <font-awesome-icon icon="fa-solid fa-power-off" /> Salir del sistema
        </CButton>

      </CHeaderNavItem>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
<!--    <CSubheader class="px-3">-->
<!--      <CBreadcrumbRouter class="border-0 mb-0"/>-->
<!--    </CSubheader>-->
  </CHeader>
</template>

<script>
import CMenu from './Menu'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import axios from "axios";
import {logo} from "@/assets/icons/logo";

export default {
  name: 'TheHeader',
  data: () => {
    return {
      username: localStorage.getItem('username'),
      sedeSeleccionada: 1,
      sedes: [],
      logoSistema:'/img/ub-logo.jpg'
    }
  },
  components: {
    TheHeaderDropdownAccnt,
    CMenu
  },
  methods: {
    logo() {
      return logo
    },
    seleccionarSede() {
      localStorage.sedeSeleccionada = this.sedeSeleccionada;
      this.$root.$emit('sedeSeleccionada', this.sedeSeleccionada);
      window.location.reload();
    },
    logout(){
      let self = this;
      axios.post(this.$apiAdress + '/api/logout?token=' + localStorage.getItem("api_token"),{})
          .then(function (response) {
            localStorage.setItem('roles', '');
            self.$router.push({ path: '/login' });
          }).catch(function (error) {
        console.log(error);
        self.$router.push({ path: '/login' });
      });
    }
  },
  beforeCreate() {
    var self = this;
    axios.post(this.$apiAdress + '/api/sede/listar' + '?token=' + localStorage.getItem("api_token"), {
      _method: 'GET'
    })
        .then(function (response) {
          self.sedes = response.data;
        })
        .catch(function (error) {
          console.log(error);
          self.$router.push({path: '/login'});
        });
  },
  mounted() {
    if (localStorage.sedeSeleccionada) {
      this.sedeSeleccionada = localStorage.sedeSeleccionada;
    } else {
      localStorage.sedeSeleccionada = this.sedeSeleccionada;
    }
    if(window.location.host==='ubacademico.vgm.tecnologia.bo'){
      this.logoSistema='/img/ub-logo.jpg';
    }else {
      this.logoSistema='/img/ub-logo-pruebas.jpg';
    }
  },
}
</script>