<template>
  <CDropdown
    inNav
    class="c-header-nav-items d-block d-md-none"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink class="border-left">
        <CIcon name="cil-user" size="xl"></CIcon>
        <CIcon name="cil-caret-bottom"></CIcon>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <form class="form-inline">
        <strong style="">Sede: </strong>
        <select v-model="sedeSeleccionada" class="form-control  form-control bg-success text-white ml-3"
                @change="seleccionarSede()" id="sedeSeleccionada">
          <option v-for="sede in sedes" :key="sede.value" :value=sede.value
                  v-bind:selected="sedeSeleccionada==sede.value ? true : false">
            {{ sede.label }}
          </option>
        </select>
      </form>
    </CDropdownHeader>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ username }}</strong>
    </CDropdownHeader>
    <CDropdownItem @click="logout()">
      <CIcon name="cil-lock-locked" /> Salir del sistema
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import axios from 'axios'
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      username: localStorage.getItem('username'),
      sedeSeleccionada: 1,
      sedes: [],
    }
  },
  methods:{
    seleccionarSede() {
      localStorage.sedeSeleccionada = this.sedeSeleccionada;
      this.$root.$emit('sedeSeleccionada', this.sedeSeleccionada);
      window.location.reload();
    },
    logout(){
      let self = this;
      axios.post(this.$apiAdress + '/api/logout?token=' + localStorage.getItem("api_token"),{})
      .then(function (response) {
        localStorage.setItem('roles', '');
        self.$router.push({ path: '/login' });
      }).catch(function (error) {
        console.log(error);
        self.$router.push({ path: '/login' });
      });
    }
  },
  beforeCreate() {
    var self = this;
    axios.post(this.$apiAdress + '/api/sede/listar' + '?token=' + localStorage.getItem("api_token"), {
      _method: 'GET'
    })
        .then(function (response) {
          self.sedes = response.data;
        })
        .catch(function (error) {
          console.log(error);
          self.$router.push({path: '/login'});
        });
  },
  mounted() {
    if (localStorage.sedeSeleccionada) {
      this.sedeSeleccionada = localStorage.sedeSeleccionada;
    } else {
      localStorage.sedeSeleccionada = this.sedeSeleccionada;
    }
    if(window.location.host==='ubacademico.vgm.tecnologia.bo'){
      this.logoSistema='/img/ub-logo.jpg';
    }else {
      this.logoSistema='/img/ub-logo-pruebas.jpg';
    }
  },
}
</script>
<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
